














































































































































































































































import GetUserInfoServiceImpl from "@/includes/logic/GetUserInfoServiceImpl";
import { AdminPermissionsEnum, PromoteAdminReq } from "@/includes/types/PromoteAdmins";
import { InputSetups } from "@/mixins/input-setups";
import { PromoteAdminsApi } from "@/includes/Api/PromoteAdmins.api";
import { errorNotification, successNotification } from "@/includes/NotificationService";

import { FieldData } from "piramis-base-components/src/components/Pi/types";
import SwitchInput from "piramis-base-components/src/components/Pi/fields/SwitchInput/SwitchInput.vue";
import ChipsInput from "piramis-base-components/src/components/Pi/fields/ChipsInput/ChipsInput.vue";
import CheckboxInput from "piramis-base-components/src/components/Pi/fields/CheckboxInput/CheckboxInput.vue";
import ConfigField from "piramis-base-components/src/components/ConfigField/ConfigField.vue";
import NestedContent from "piramis-base-components/src/components/Pi/components/NestedContent.vue";
import UsersInfoMixin from "piramis-base-components/src/shared/logic/getUsersInfo/UsersInfoMixin"
import TextInput from "piramis-base-components/src/components/Pi/fields/TextInput/TextInput.vue";

import { Component, Emit, Mixins, Prop, VModel, Watch } from 'vue-property-decorator';

const STORIES_PERMISSIONS_COUNT = 3

@Component({
  data() {
    return {
      STORIES_PERMISSIONS_COUNT,
      AdminPermissionsEnum
    }
  },
  components: {
    NestedContent,
    SwitchInput,
    ChipsInput,
    CheckboxInput,
    ConfigField,
    TextInput
  }
})
export default class ReceivedAdminsPermissions extends Mixins(InputSetups, UsersInfoMixin) {
  @VModel() isAddAdminModalOpen!: boolean

  @Prop() activeUserId!: string | null

  @Prop() admins!: Record<number, string>

  @Emit()
  modalClose() {
    return true
  }

  usersInfoService = new GetUserInfoServiceImpl(this.$store.getters.chatId)

  storiesPermissionsAll = false

  storiesPermissionsBlock = false

  promoteAdminModel: Pick<PromoteAdminReq, 'title' | 'anon'> & { permissions: Record<AdminPermissionsEnum, boolean> } = {
    permissions: { ...this.defaultPermissions() },
    title: '',
    anon: false
  }

  localUserId: Array<number> = []

  permissionsLoading = false

  showAddViaBotAlert = false

  @Watch('activeUserId')
  onActiveUserIdChange(userId: string | null) {
    if (userId) {
      const parsedId = Number.parseInt(userId)

      if (!Number.isNaN(parsedId)) {
        this.loadPermissions(parsedId)

        this.localUserId = [ parsedId ]
      }
    }
  }

  get modalTitle() {
    if (this.activeUserId) {
      const username = this.admins[+this.activeUserId] ?? ''

      return this.$t('promote_group_admin_modal_title', [ username ]).toString()
    } else {
      return this.$t('add_group_admin_modal_title').toString()
    }
  }

  get activeStoriesPermissionsLen() {
    return [
      this.promoteAdminModel.permissions.CanPostStories,
      this.promoteAdminModel.permissions.CanEditStories,
      this.promoteAdminModel.permissions.CanDeleteStories
    ]
      .filter(p => p).length
  }

  getOptionsFromPermissionsRecord(permissions: Record<AdminPermissionsEnum, boolean>) {
    return Object.entries(permissions).reduce((acc: Array<AdminPermissionsEnum>, [ key, value ]) => {
      if (value) {
        acc.push(key as AdminPermissionsEnum)
      }

      return acc
    }, [])
  }

  promoteAdmins() {
    this.$baseTemplate.loader.open()

    PromoteAdminsApi.promoteAdmin('tg', {
      chat_id: this.$store.getters.chatId,
      user_id: this.localUserId[0],
      anon: this.promoteAdminModel.anon,
      title: this.promoteAdminModel.title,
      options: this.getOptionsFromPermissionsRecord(this.promoteAdminModel.permissions)
    })
      .then(({ data }) => {
        successNotification(this.$t('add_admin_success_notification').toString())

        this.$store.dispatch('requestChatConfig', { id: this.$store.getters.chatId })

        this.isAddAdminModalOpen = false
      })
      .catch((error) => {
        errorNotification(error)

        this.showAddViaBotAlert = true
      })
      .finally(() => {
        this.$baseTemplate.loader.close()
      })
  }

  defaultPermissions() {
    return {
      [AdminPermissionsEnum.CanChangeInfo]: false,
      [AdminPermissionsEnum.CanInviteUsers]: false,
      [AdminPermissionsEnum.CanPromoteMembers]: false,
      [AdminPermissionsEnum.CanRestrictMembers]: false,
      [AdminPermissionsEnum.CanDeleteMessages]: false,
      [AdminPermissionsEnum.CanPinMessages]: false,
      [AdminPermissionsEnum.CanManageVideoChats]: false,
      [AdminPermissionsEnum.CanManageChat]: false,
      [AdminPermissionsEnum.CanManageTopics]: false,
      [AdminPermissionsEnum.CanPostStories]: false,
      [AdminPermissionsEnum.CanEditStories]: false,
      [AdminPermissionsEnum.CanDeleteStories]: false,
    }
  }

  toggleStoriesPermissionsBlock() {
    this.storiesPermissionsBlock = !this.storiesPermissionsBlock
  }

  loadPermissions(user_id: number) {
    this.permissionsLoading = true

    PromoteAdminsApi.getAdminPerms('tg', {
      chat_id: this.$store.getters.chatId,
      user_id
    })
      .then(({ data }) => {
        this.setListOptionsToRecord(data.options);

        this.promoteAdminModel.anon = data.anon
        this.promoteAdminModel.title = data.title
      })
      .catch(errorNotification)
      .finally(() => {
        this.permissionsLoading = false
      })
  }

  afterModalClose() {
    this.modalClose()

    this.resetState()
  }

  resetState() {
    this.localUserId = []
    this.promoteAdminModel.anon = false
    this.promoteAdminModel.title = ''
    this.promoteAdminModel.permissions = { ...this.defaultPermissions() }

    this.storiesPermissionsAll = false
    this.storiesPermissionsBlock = false
  }

  setListOptionsToRecord(options: Array<AdminPermissionsEnum>) {
    options.forEach(option => {
      if (this.promoteAdminModel.permissions?.[option] !== undefined) {
        this.$set(this.promoteAdminModel.permissions, option, true)
      }
    })

    this.storiesPermissionsAll = this.activeStoriesPermissionsLen !== 0
  }

  userSetter(args: FieldData) {
    args.setter = (value: Array<number>) => {
      if (value.length === 0) {
        args.model[args.key] = value
      }

      if (args.model[args.key].length === 0) {
        this.userInfoModelSetter(args, { model: value }, this.localUserId, this.usersInfoService)
      }
    }

    return args
  }

  storiesSetter(args: FieldData) {
    args.setter = value => {
      args.model[args.key] = value

      this.storiesPermissionsAll = this.activeStoriesPermissionsLen !== 0
    }

    return args
  }

  storiesAllSetter(args: FieldData) {
    args.setter = value => {
      args.model[args.key] = value

      this.promoteAdminModel.permissions.CanPostStories = value
      this.promoteAdminModel.permissions.CanEditStories = value
      this.promoteAdminModel.permissions.CanDeleteStories = value
    }

    return args
  }

  format(tag: string | null) {
    return this.formatter(this.usersInfoService.usersInfos, tag)
  }
}
